<template>
    <div class="container">
        <h1>Ankete yönlendiriliyor...</h1>
    </div>
</template>

<script>
export default {
    name: 'RedirectComponent',
    data() {
        return {
            surveyLink: null
        }
    },
    created() {
        if (!(this.$route.params.shortCode) || (this.$route.params.shortCode.length !== 6)) {
            this.$router.push({ path: '/404' });
            return;
        }


        this.$api()
            .get("public/shortener/" + this.$route.params.shortCode)
            .then((response) => {
                if (response != null) {
                    this.$router.push({ path:response });
                    return;
                }

            }).catch(() => {
            });
    }
}
</script>