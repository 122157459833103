import axios from "axios";

export default {
    install: (app) => {
        app.config.globalProperties.$api = () => {
            const instance = axios.create({
                baseURL: window.config.BaseUrl
            });
            const config = {
                headers: {
                    //Authorization: 'Bearer ' + window.localStorage.getItem('token')
                }
            };

            return {
                get: (url) => {
                    var r = instance.get(url, config);
                    return new Promise(function (resolve, reject) {
                        r.then((response) => {
                            if (response.status == 200) {
                                    resolve(response.data);
                            }
                            else {
                                reject(response.data);
                            }
                        })
                            .catch((e) => {
                                console.log(e.response.status);
                                window.location = "/404";
                        });

                    });

                },
                post: (url, body) => {
                    return instance.post(url, body, config)
                }
            }
        }
    }
};
