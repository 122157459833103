import { createRouter, createWebHistory } from "vue-router";
import Survey from '../src/components/SurveyComponent.vue';
import Redirect from '../src/components/RedirectComponent.vue';
import _404 from '../src/views/_404.vue';



const routes = [
    {
        path: '/:shortCode',
        name: 'Redirect',
        component: Redirect
    },
    {
        path: '/:type/:id',
        name: 'Survey',
        component: Survey
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: _404,
    },
];

const router = createRouter({
    history: createWebHistory("/"),
    routes
})

export default router;
